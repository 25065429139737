import React from "react"
import { isMobile } from "react-device-detect/main"
import TrackVisibility from "react-on-screen"
import "./transitions.css"

export default function CGPTransitionRowComponent({ children, className }) {
  return isMobile ? (
    <div className={(className || "") + " CGPTransitionRowComponent"}>
      {children}
    </div>
  ) : (
    <TrackVisibility
      partialVisibility
      className={(className || "") + " CGPTransitionRowComponent"}
    >
      {children}
    </TrackVisibility>
  )
}
