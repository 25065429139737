import React from "react"

export default function CGPTransitionRowSpawnComponent({
  children,
  isVisible = "true",
  className,
  disable,
}) {
  return (
    <div
      className={`CGPTransitionRowSpawnComponent ${className} ${
        !disable && isVisible && "show"
      }`}
    >
      {children}
    </div>
  )
}
