import React from "react"

export default function CGPTransitionRowFixedComponent({
  children,
  className,
}) {
  return (
    <div className={`CGPTransitionRowFixedComponent ${className || ""}`}>
      {children}
    </div>
  )
}
